/** ----------------------------------------
     Package
 ---------------------------------------- */

import { isString } from 'lodash';

/** ----------------------------------------
    Cookies Consent
 ---------------------------------------- */

export class Inject {

    /** ----------------------------------------
        Types
     ---------------------------------------- */

    private _style: any;
    private _styleInject: any;
    private _script: any;
    private _scriptInject: any;

    /** ----------------------------------------
         Validation Helpers
     ---------------------------------------- */

    _isString(value: any) {
        return _.isString(value);
    }

    _isUrl(url: string) {
        const validate = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        const regex = new RegExp(validate);
        return url.match(regex);
    }

    /** ----------------------------------------
        Inject Stylesheet
     ---------------------------------------- */

    set style(data: any) {
        this._styleGeneral();
        this._isString(data) && this._isUrl(data) ? this._styleFile(data) : this._styleInline(data);
    }

    get style() {
        return this._styleInject;
    }

    /** ----------------------------------------
        Inject Style General
     ---------------------------------------- */

    _styleGeneral() {
        const style = document.createElement('link');
        style.type = 'text/css';
        style.rel = 'stylesheet';
        this._style = style;
    }

    /** ----------------------------------------
        Inject Style File
     ---------------------------------------- */

    _styleFile(file: string) {
        this._style.href = file;

        this._styleInject = new Promise(resolve => {
            document.getElementsByTagName('head')[0]
                .appendChild(this._style);

            resolve();
        });
    }

    /** ----------------------------------------
        Inject Style File
     ---------------------------------------- */

    _styleInline(code: string) {
        this._style.setAttribute('href', 'data:text/css;charset=UTF-8,' + encodeURIComponent(code));

        document.getElementsByTagName('head')[0]
            .appendChild(this._style);
    }

    /** ----------------------------------------
         Inject Script
     ---------------------------------------- */

    set script(data: any) {
        this._scriptGeneral();
        this._isString(data) ? this._scriptFile(data) : this._injectScript(data);
    }

    get script() {
        return this._scriptInject;
    }

    /** ----------------------------------------
         Inject Script General
     ---------------------------------------- */

    _scriptGeneral() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        this._script = script;
    }

    /** ----------------------------------------
        Inject Script File
     ---------------------------------------- */

    _scriptFile(file: string) {
        this._script.src = file;

        this._scriptInject = new Promise(resolve => {
            document.getElementsByTagName('head')[0]
                .appendChild(this._script);

            resolve();
        })
    }

    /** ----------------------------------------
        Inject Script
     ---------------------------------------- */

    _injectScript(script) {
        window.onload = script;
    }

}
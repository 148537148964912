

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { FormMixin } from '../../mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                mainClass: 'form-choice',
            };
        },

        mixins: [
            FormMixin,
        ],

        computed: {
            cChoiceId() {
                return `${ this.cId }_${ this.val }`;
            },

            cClass() {
                let classes = [this.mainClass];

                if (this.type)
                    classes.push(`form-${ this.type }`);

                if (this.error)
                    classes.push('has-error');

                if (!this.value)
                    classes.push('has-novalue');

                if (this.value)
                    classes.push('has-value');

                return classes.join(' ');
            }
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class FormChoice extends Vue {
        @Prop() value!: any;

        @Prop() val!: any;

        @Prop() type!: any;
}


/**
 * This mixin adds logic for every form field: 
 * inputs, textarea, select etc.
 */

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Prop, Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    $_veeValidate: {
        name() {
            return this.name;
        },
        value () {
            return this.model;
        }
    },

    data() {
        return {
            modelProxy: '',
            showError: true,
            hasBlur: true,
            hasFocus: false,
        };
    },

    computed: {
        model: {
            get () {
                return this.value;
            },
            set (value) {
                this.modelProxy = value;
            }
        },

        cId() {
            return this.id || this.name;
        },

        cErrorClass() {
            return `form-error form-error--${this.errorPosition}`;
        },
    },

    methods: {
        onInput: function(e) {
            this.$emit('input', this.modelProxy);
            this.updateShowError('input');
        },

        onBlur: function(e) {
            this.hasBlur = true;
            this.hasFocus = false;
            this.$emit('blur', this.modelProxy);
            this.updateShowError('blur');
        },

        onFocus: function(e) {
            this.hasBlur = false;
            this.hasFocus = true;
            this.$emit('focus', this.modelProxy);
            this.updateShowError('focus');
        },

        onChange: function(e) {
            this.onInput(e);
        },

        updateShowError(action) {
            if (this.errorOn === action) this.showError = true;
            if (this.errorOff === action) this.showError = false;
        },
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class FormMixin extends Vue {
    @Prop() id!: string;
    @Prop() name!: string;
    @Prop() form!: string;
    @Prop() validate!: string;
    @Prop() error!: string;
    @Prop({ default: 'blur' }) errorOn!: string;
    @Prop({ default: 'input' }) errorOff!: string;
    @Prop() tabindex!: number;
    @Prop(Boolean) autofocus!: boolean;
    @Prop(Boolean) autocomplete!: boolean;
    @Prop(Boolean) disabled!: boolean;
    @Prop(Boolean) required!: boolean;
    @Prop(Boolean) readonly!: boolean;
    @Prop({
        default: 'after',
        validator: (x) => ['before', 'after'].indexOf(x) !== -1
    }) errorPosition!: string;
}
/** ----------------------------------------
    Imports
 ---------------------------------------- */

import Cookies from 'js-cookie';

/** ----------------------------------------
     State
 ---------------------------------------- */

const state = () => {
    return {
        data: {},
        timestamp: null,
        token: Cookies.get('token') || '',
    }
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default state;
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Transitions
---------------------------------------- */

import * as transition from '@router/transitions/transition' ;

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if(!vm.$options.transition || !vm.$options.transition.enter)
                return next();

            const route = [to, from];

             vm.$options.transition.enter({
                 el: vm.$el,
                 parent: vm.$parent.$el,
                 route: route
             }, transition.generic);

             next();
        });
    },
    beforeRouteUpdate (to, from, next) {
        const compare = from.fullPath.replace('#', '') === to.fullPath.replace('#', '');

        if(!this.$options.transition || !this.$options.transition.update && compare)
            return next();

        const route = [to, from];

        this.$options.transition.update({
            el: this.$el,
            parent: this.$parent.$el,
            route: route
        }, transition.generic);

        next();
    },
    beforeRouteLeave (to, from, next) {
        if(!this.$options.transition || !this.$options.transition.leave)
            return next();

        const route = [to, from];

        this.$options.transition.leave({
            el: this.$el,
            parent: this.$parent.$el,
            route: route
        }, transition.generic);

        next();
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export class TransitionMixin extends Vue {

}
/* ----------------------------------------
    Cookie Configuration
---------------------------------------- */

const state = {
    active: false,
    status: ''
};

const setState = status => {
    state.active = status === 'allow';
    state.status = status;
};

const consent = (exe, messageStrings) => {
    return {
        palette: {
            popup: {
                background: '#000'
            },
            button: {
                background: '#FFF'
            }
        },
        type: 'opt-in',
        content: {
            message: messageStrings.text,
            allow: messageStrings.accept,
            dismiss: messageStrings.deny,
            link: messageStrings.info_btn,
            href: `/${messageStrings.info_url}`
        },
        onStatusChange: status => {
            setState(status);
            exe(status);
        },
        onInitialise: status => {
            setState(status);
            exe(status);
        }
    }
};

/* ----------------------------------------
    Export Module
---------------------------------------- */

module.exports = {
    consent,
    state
};
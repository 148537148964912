/** ----------------------------------------
    Imports
---------------------------------------- */

import Vue from 'vue';
import store from '@store/store';

/** ----------------------------------------
    Filter
---------------------------------------- */

Vue.filter('trans', (value: any) => {
    const trans = store.state.trans.data[value];
    if (trans) return trans;
    return value;
});

/** ----------------------------------------
    Generator helper
 ---------------------------------------- */

export default class GeneratorHelper {

    /**
     * Run generator till its done
     * 
     * @param  {Generator} gen
     */
    static async runGenerator (gen: Generator) {
        const next = gen.next();

        if (next.done)
            return true;
        
        let value = next.value;
        let isPromise = typeof value.then == 'function';
        
        if (isPromise) {
            return value.then(resolve => {
                return this.runGenerator(gen);
            })
        } else {
            return this.runGenerator(gen);
        }
    };
}
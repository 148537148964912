/** ----------------------------------------
     Cookies Configuration
 ---------------------------------------- */

import { bugherd } from '@config';

/** ----------------------------------------
     Helper
 ---------------------------------------- */

import { Inject } from '@helpers/Inject';
const inject = new Inject();

/** ----------------------------------------
    Cookies Consent
 ---------------------------------------- */

export class Bugherd {

    /** ----------------------------------------
         Constructor
     ---------------------------------------- */

    constructor() {
        this._init();
    }

    /** ----------------------------------------
         Run Cookie Consent
     ---------------------------------------- */

    _injectScript() {
        inject.script = bugherd;
    }

    /** ----------------------------------------
         Initialize
     ---------------------------------------- */

    _init() {
        this._injectScript();
    }

}
/** ----------------------------------------
    Root events directive
 ---------------------------------------- */

const directive = {
    name: 'root-events',
    options: {
        inserted: function (el, binding, vnode, oldvnode) {
            let modifiers = binding.modifiers;
            let eventNames = [];
    
            if (modifiers) {
                Object.keys(modifiers).forEach(key => {
                    eventNames.push(key);
                });
            } else {
                eventNames = [
                    'click',
                    'mousemove',
                    'keydown',
                    'keyup',
                ];
            }
    
            for (let eventName of eventNames) {
                el.addEventListener(eventName, (event) => {
                    vnode.context.$root.$emit(`root::${eventName}`, event);
                });
            }
        }
    }
}

export default directive;
/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'list',
    fn: (array: [string, number]) => {
        if (!array) return '';
        return array.join(', ');
    }
}

export default filter;
/** ----------------------------------------
    String helper
 ---------------------------------------- */

export default class StringHelper {
    
    /**
     * @param  {string} string
     * @returns {string}
     */
    static capitalize(string: string) {
        if (!string) return '';
        string = string.toString();
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    /**
     * @param  {string} string
     * @returns {string}
     */
    static lowercase(string: string) {
        if (!string) return '';
        string = string.toString();
        return string.toLowerCase();
    }

    /**
     * @param  {string} string
     * @param  {number} length
     * @returns {string}
     */
    static trim(string: string, length: number) {
        if (!string) return '';
        string = string.toString();
        return string.substring(0, length) + '...';
    }

    /**
     * @param  {string} string
     * @returns {string}
     */
    static pascalCase(string: string) {
        return string
            .split('-')
            .map(str => this.capitalize(str))
            .join('');
    }
}
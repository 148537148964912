/**
 * This mixin adds logic specific for form inputs
 */

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Prop, Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    data() {
        return {
            mainClass: 'form-input',
        }
    },

    computed: {
        count() {
            return (this.value) ? this.maxlength - this.value.length : '';
        },

        topLabel() {
            return (this.hasFocus || this.value);
        },

        _isInput() {
            return true;
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class FormInputMixin extends Vue {
    @Prop() placeholder!: string;
    @Prop() value!: string;
    @Prop() list!: string;
    @Prop() min!: number;
    @Prop() max!: number;
    @Prop() maxlength!: number;
}
/** ----------------------------------------
    IBAN
---------------------------------------- */

import { Validator } from 'vee-validate';

Validator.extend('dutch_postal', {
    validate (value) {
        var regex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
        return regex.test(value);
    },
    getMessage (field) {
        return `The ${field} field must be a valid dutch postal`;
    }
});
/** ----------------------------------------
    Store helper
 ---------------------------------------- */

export default class StoreHelper {
    
    /**
     * Reset state mutation
     * receives the state as a function
     * 
     * @param  {function} stateFn
     */
    static resetStateMutation(stateFn) {
        return {
            clearState(state: any) {
                let originalState = stateFn();
                Object.keys(originalState).forEach(key => {
                    state[key] = originalState[key];
                });
            }
        }
    }
}
/** ----------------------------------------
    UserJob
 ---------------------------------------- */

import UserGenerator from '@generator/user';
import { GeneratorHelper } from '@fruitpackage/helpers';

export default class UserJob  {
    vue: any;

    protected constructor(vue: any, setting: any) {
        this.vue = vue;
    }

    public async init() {
        const gen = UserGenerator(this.vue);
        return GeneratorHelper.runGenerator(gen).then(response => {
            return response;
        });
    }
}
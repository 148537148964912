/** ----------------------------------------
    Services
 ---------------------------------------- */

import { SettingsService } from '@store/service/SettingsService';

/** ----------------------------------------
    Actions
 ---------------------------------------- */

const actions = {

    /** ----------------------------------------
        FETCH CONFIG
    ---------------------------------------- */

    async fetch({ commit }: any) {
        const settingsService = new SettingsService('api', this);
        return settingsService.getSettings().then(content => {
            commit('setData', content);
            commit('setTimestamp', new Date().getTime());
        });
    },
};

/** ----------------------------------------
    Export Actions
 ---------------------------------------- */

export default actions;
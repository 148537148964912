/** ----------------------------------------
    String helper
 ---------------------------------------- */

export default class ObjectHelper {
    
    /**
     * @param  {string} string
     * @returns {Boolean}
     */
    static isValidPath(obj: {Object}, str: string) {
        const prop = str.substring(0, str.indexOf('.'));
        const rest = str.substring(str.indexOf('.') + 1);

        if (str.indexOf('.') === -1)
            return true;

        if (!obj.hasOwnProperty(prop))
            return false;

        return this.isValidPath(obj[prop], rest);
    }

    /**
     * @param  {string} string
     * @returns {Boolean}
     */
    static pathValue(obj: {Object}, str: string) {
        const prop = str.substring(0, str.indexOf('.'));
        const rest = str.substring(str.indexOf('.') + 1);

        if (str.indexOf('.') === -1)
            return obj[rest];

        if (!obj.hasOwnProperty(prop))
            return null;

        return this.pathValue(obj[prop], rest);
    }

    static removeEmptyProps(obj: {Object}) {
        Object.entries(obj).forEach(([key, val]) => {
            if (val && typeof val === 'object') this.removeEmptyProps(val);
            else if (val == null || val == '') delete obj[key];
        });
    }

    static fillProps(obj1, obj2) {
        Object.entries(obj1).forEach(([key, val]) => {
            if (val && typeof val === 'object') this.fillProps(val, obj2[key]);
            obj1[key] = obj2[key];
        });
    }

    static isFilled(obj: {Object}) {
        let filled = true;
        Object.values(obj).some(val => {
            if (val && typeof val === 'object') filled = this.isFilled(val);
            else if (val == null || (typeof val === 'string' && val == '')) {
                filled = false;
                return true;
            }
        });
        return filled;
    }
}
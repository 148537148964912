/** ----------------------------------------
     Module Interface
 ---------------------------------------- */

import module from './interface';

/** ----------------------------------------
    State
 ---------------------------------------- */

const getters = {};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default getters
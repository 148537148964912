/** ----------------------------------------
    Imports
---------------------------------------- */

import moment from 'moment';

/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'moment',
    fn: (timestamp: number) => {
        return new moment(timestamp);
    }
}

export default filter;
/** ----------------------------------------
    Imports
 ---------------------------------------- */

import { TransService } from '@store/service/TransService';

/** ----------------------------------------
    Actions
 ---------------------------------------- */

const actions = {
    setToastData({ commit }: any, payload: any) {
        const { message, button, link, color } = payload;
        commit('setToastMessage', message);
        commit('setToastButton', button);
        commit('setToastLink', link);
        commit('setToastColor', color);
    },
    setToastMessage({ commit }: any, payload: any) {
        commit('setToastMessage', payload);
    },
    setToastButton({ commit }: any, payload: any) {
        commit('setToastButton', payload);
    },
    setToastLink({ commit }: any, payload: any) {
        commit('setToastLink', payload);
    },
    setToastColor({ commit }: any, payload: any) {
        commit('setToastColor', payload);
    },
};

/** ----------------------------------------
    Export Actions
 ---------------------------------------- */

export default actions;

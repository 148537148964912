/**
 * This mixin adds usefull methods for when using slots
 */

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({

    methods: {
        hasSlot(name = 'default') {
            return !!this.$slots[ name ] || !!this.$scopedSlots[ name ];
        },

        getSlot(name = 'default') {
            return this.$slots[ name ] || this.$scopedSlots[ name ];
        }
    }
})

/* ----------------------------------------
    Exports
---------------------------------------- */

export default class SlotsMixin extends Vue {}
/* ----------------------------------------
    Imports
---------------------------------------- */

import { ApiService } from './ApiService';

/* ----------------------------------------
    API Service
---------------------------------------- */

export class RoutesService extends ApiService {

    /* ----------------------------------------
        Constructor
    ---------------------------------------- */

    protected constructor(apiName: string, store) {
        super(apiName, '', store);
    }

    /* ----------------------------------------
        Constructor
    ---------------------------------------- */

    async getRoutes() {
        return this.get('/page/router').then(response => {
            if (response instanceof Error)
                throw response;

            return response;
        });
    }
}
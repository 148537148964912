
/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import { DropPositionMixin } from './../mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
  data() {
    return {
      active: false,
      proxyPosition: null,
      dropClass: 'dropdown__target'
    };
  },

  mixins: [DropPositionMixin],

  created() {
    this.proxyPosition = this.position;
  },

  mounted() {
    this.rootListener();
  },

  computed: {
    cClass() {
      let classes = ['dropdown'];

      if (this.active) classes.push('is-active');

      return classes.join(' ');
    },

    cTargetClass() {
      return `dropdown__target dropdown__target--${this.proxyPosition}`;
    }
  },

  methods: {
    rootListener() {
      this.$root.$on('root::dropdown::toggle', (event, uid) => {
        if (this._uid === uid) return;
        this.active = false;
      });

      this.$root.$on('root::dropdown::close', event => {
        this.active = false;
      });

      this.$root.$on('root::click', event => {
        this.active = false;
      });
    }
  }
})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class Dropdown extends Vue {
  @Prop({ default: 'left' }) position!: string;

  @Emit()
  toggle(event) {
    this.active = !this.active;
    this.$root.$emit('root::dropdown::toggle', event, this._uid);
    return this.active;
  }

  @Watch('active')
  onActiveChange(val: boolean) {
    if (!val) {
      this.resetProxyPosition();
      return;
    }

    this.$nextTick(function() {
      this.updateProxyPosition();
    });
  }

  @Watch('$route')
  onRouteChange() {
    this.active = false;
  }
}

/* ----------------------------------------
    Meta SEO Configuration
---------------------------------------- */

const meta = {
};

/* ----------------------------------------
    Export Module
---------------------------------------- */

module.exports = {
    meta
};
/** ----------------------------------------
    Imports
 ---------------------------------------- */

import { UserService } from "@store/service/UserService";
import Cookies from "js-cookie";
import router from "@router/router";
import store from '@store/store';
import UserJob from "@job/UserJob";

/** ----------------------------------------
    Actions
 ---------------------------------------- */

const actions = {
  /** ----------------------------------------
        Login user
    ---------------------------------------- */

  async login({ commit }: any, payload: any) {
    const userService = new UserService("api", this);
    return userService.postLogin(payload).then(response => {
      if (response instanceof Error) return response;

      // Set token
      commit("setToken", response.token);
      Cookies.set("token", response.token);

      // Retrieve user data
      const userJob = new UserJob(this._vm);
      userJob.init().then(response => {
        store.dispatch('setToastMessage', 'Succesvol ingelogd.')
        store.dispatch('setToastButton', '');

        // Redirect to home
        router.push("/home");
      });
    });
  },

  /** ----------------------------------------
        Register
    ---------------------------------------- */

  async register({ commit }: any, payload: any) {
    const userService = new UserService("api", this);
    return userService.postRegister(payload).then(response => {
      if (response instanceof Error) return response;

      store.dispatch('setToastMessage', 'Succesvol geregistreerd 👌')
      store.dispatch('setToastButton', '');

      router.push({ path: "/login", params: { registered: true } });
    });
  },

  /** ----------------------------------------
        Update
    ---------------------------------------- */

  async update({ commit }: any, payload: any) {
    const userService = new UserService("api", this);
    return userService.putUpdate(payload.id, payload).then(response => {
      if (response instanceof Error) return response;
      // store.dispatch('setToastMessage', 'Message2.');
      // store.dispatch('setToastButton', '');
      return response.data;
    });
  },

  /** ----------------------------------------
        Forgot
    ---------------------------------------- */

  async forgot({ commit }: any, payload: any) {
    const userService = new UserService("api", this);
    return userService.postForgot(payload);
  },

  /** ----------------------------------------
        Reset
    ---------------------------------------- */
  async reset({ commit }: any, payload: any) {
      const userService = new UserService("api", this);
      return userService.postReset(payload);
  },

  /** ----------------------------------------
        Logout user
    ---------------------------------------- */

  async logout({ commit }: any) {
    const userService = new UserService("api", this);
    return userService.getLogout().then(response => {
      this.dispatch("user/removeToken");
      this.dispatch("clearStore");
      router.push("/login");
      // logout successful
      store.dispatch('setToastMessage', 'Succesvol uitgelogd, tot snel!');
      store.dispatch('setToastButton', '');
    });
  },

  /** ----------------------------------------
        Remove user token
    ---------------------------------------- */

  removeToken({ commit }: any) {
    commit("emptyToken");
    Cookies.remove("token");
  },

  /** ----------------------------------------
        Fetch user
    ---------------------------------------- */

  async fetch({ commit }: any) {
    const userService = new UserService("api", this);
    return userService.getUserData().then(data => {
      data = data.data;
      commit("setData", data);
      commit("setTimestamp", new Date().getTime());
    });
  }
};

/** ----------------------------------------
    Export Actions
 ---------------------------------------- */

export default actions;

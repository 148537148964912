/** ----------------------------------------
    Currency Code
---------------------------------------- */

const code: any = {
    'dollar': 'USD',
    'euro': 'EUR',
    'pound': 'GBP'
};

/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'currency-code',
    fn: (number: number, value: string = 'euro') => {
        return `${ code[value] } ${ number.toFixed(2).toLocaleString() }`;
    }
}

export default filter;
/** ----------------------------------------
    Node Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Slide
 ---------------------------------------- */

const slideInRight = (el, duration = 750, delay = 0) => {
    const right = -Math.abs(el.offsetWidth);

    return new Promise((resolve) => {
        anime({
            targets: el,
            marginTop: [right, 0],
            duration: duration,
            delay: delay,
            easing: 'easeInQuart',
            complete: () => resolve()
        });
    });
};

const slideOutRight = (el, duration = 750, delay = 0) => {
    const right = -Math.abs(el.offsetWidth);

    return new Promise((resolve) => {
        anime({
            targets: el,
            right: [0, right],
            duration: duration,
            delay: delay,
            easing: 'easeOutQuart',
            complete: () => resolve()
        });
    });
};

/** ----------------------------------------
    Export Modules
 ---------------------------------------- */

export {
    slideInRight,
    slideOutRight
};
/** ----------------------------------------
    Interface
 ---------------------------------------- */

import { UserStore } from '@interface/store/User';

/** ----------------------------------------
    State
 ---------------------------------------- */

const getters = {
    isLoggedIn: (state: UserStore) => !!state.token,
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default getters
/** ----------------------------------------
    Interface
 ---------------------------------------- */

import { ConfigStore } from '@interface/store/Config';

/** ----------------------------------------
    State
 ---------------------------------------- */

const getters = {

};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default getters
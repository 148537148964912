

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class App extends Vue {}

/** ----------------------------------------
    IBAN
---------------------------------------- */

import { Validator } from 'vee-validate';

Validator.extend('dutch_phone', {
    validate (value) {
        const vast_nummer = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
        const mobiel_nummer = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
        return (vast_nummer.test(value) || mobiel_nummer.test(value));
    },
    getMessage (field) {
        return `The ${field} field must be a valid dutch phone number`;
    }
});
/** ----------------------------------------
    Module Interface
 ---------------------------------------- */

import { RoutesService } from '@store/service/RoutesService';

/** ----------------------------------------
    State
 ---------------------------------------- */

const actions = {
    setMenu({ commit }: any, payload: any) {
        commit('setMenu', payload);
    },

    async fetch({ commit }: any, payload: any) {
        const routesService = new RoutesService('api', this);
        return routesService.getRoutes().then(data => {
            commit('setRoutes', data);
        });
    }
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default actions;
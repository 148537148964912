
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import $ from 'jquery';
import slick from 'slick-carousel';
import uniqid from 'uniqid';
import ArrowBackSvg from '@svg/ArrowBack.vue';
import ArrowBackWhiteSvg from '@svg/ArrowBackWhite.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  data() {
    return {
      uniqueId: uniqid(),
      isHidden: true,
      currentSlidesToShow: null
    };
  },
  components: {
    ArrowBackSvg,
    ArrowBackWhiteSvg
  },
  props: {
    currentLength: {
      type: Number,
      required: false
    },
    slidesToShow: {
      type: Number,
      required: false,
      default: 2.3
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    simple: {
      type: Boolean,
      required: false,
      default: false
    },
    footerSlider: {
      type: Boolean,
      required: false,
      default: false
    },
    bg_color: {
      type: String,
      required: false
    }
  },
  methods: {
    responsiveSlideN() {
      let slideN = this.slidesToShow;
      window.innerWidth < 1260 && (slideN = 2);
      window.innerWidth < 768 && (slideN = 1.1);
      this.simple && (slideN = 1);
      this.footerSlider && (slideN = 5);
      return slideN;
    },
    slide() {
      // for some reason stuff breaks if you dont have 'slick' here
      slick;
      $('.slider_' + this.uniqueId).slick({
        infinite: !!this.simple,
        slidesToShow: this.responsiveSlideN(),
        arrows: true,
        dots: !!this.simple,
        slidesToScroll: Math.floor(this.responsiveSlideN()),
        prevArrow: $(`.prev_${this.uniqueId}`),
        nextArrow: $(`.next_${this.uniqueId}`),
        autoplay: !!(this.simple || this.footerSlider),
        autoplaySpeed: 10000,
        variableWidth: this.footerSlider || this.simple,
        adaptiveHeight: false
      });
    },
    reloadSlider() {
      slick;
      let $this = this;
      $('.slider_' + this.uniqueId).slick('unslick');
      setTimeout(() => {
        $this.slide();
      }, 20);
    }
  },
  mounted() {
    let $this = this;
    setTimeout(() => {
      this.slide();
      this.isHidden = false;
    }, 700);

    window.onresize = function(event) {
      // on resize, if number of slides to show: reload slider
      if ($this.currentSlidesToShow !== $this.responsiveSlideN()) {
        $this.reloadSlider();
        $this.currentSlidesToShow = $this.responsiveSlideN();
      }
    };
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Slider extends Vue {}

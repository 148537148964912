/** ----------------------------------------
    Front routes
 ---------------------------------------- */

import authRoutes from '@router/routes/auth';
import errorsRoutes from '@router/routes/errors';

const routes = [
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      if (to.path === '/') {
        next('/home');
      }

      next();
    },
    component: () => import('@view/layout/Master.vue'),
    children: [
      // {
      //   path: "/dev",
      //   name: "dev",
      //   component: () => import("@view/templates/Dev.vue")
      // },
      {
        path: '/programma/:id/*',
        name: 'programma/:id',
        component: () => import('@view/templates/EventDetail.vue'),
        props: true
      },
      {
        path: '/museum/:id/*',
        name: 'museum/:id',
        component: () => import('@view/templates/MuseumDetail.vue'),
        props: true
      },
      {
        path: '/onze-routes/:id/*',
        name: 'onze-routes/:id',
        component: () => import('@view/templates/CuratedRouteDetail.vue'),
        props: true
      },
      {
        path: '/ambassadeurs/:id/*',
        name: 'ambassadeurs/:id',
        component: () => import('@view/templates/AmbassadorDetail.vue'),
        props: true
      },
      {
        path: '/themas/:id/*',
        name: 'themas/:id',
        component: () => import('@view/templates/ThemeDetail.vue'),
        props: true
      },
      {
        path: '/tentoonstellingen/:id/*',
        name: 'tentoonstellingen/:id',
        component: () => import('@view/templates/ExhibitionDetail.vue'),
        props: true
      },
      {
        path: '/articles/:id/*',
        name: 'articles/:id',
        component: () => import('@view/templates/ArticleDetail.vue'),
        props: true
      },
      ...authRoutes,
      ...errorsRoutes
    ]
  }
];

/** ----------------------------------------
    Export Modules
 ---------------------------------------- */

export default routes;

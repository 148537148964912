/** ----------------------------------------
    Node Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Fade
 ---------------------------------------- */

const fadeIn = (el, duration = 750, delay = 0) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            opacity: [0, 1],
            duration: duration,
            delay: delay,
            easing: 'easeInQuart',
            complete: () => resolve()
        });
    });
};

const fadeOut = (el, duration = 750, delay = 0) => {
    return new Promise((resolve) => {
        return anime({
            targets: el,
            opacity: [1, 0],
            duration: duration,
            delay: delay,
            easing: 'easeOutQuart',
            complete: () => resolve()
        });
    });
};

/** ----------------------------------------
    Export Modules
 ---------------------------------------- */

export {
    fadeIn,
    fadeOut
};
/** ----------------------------------------
    Imports
 ---------------------------------------- */

import store from "@store/store";

/** ----------------------------------------
    Export
 ---------------------------------------- */

export default function auth({ next, to }) {
  const user = store.state.user;

  if (to.meta.reqAuth && !user.token)
    return next({ path: "/login", query: { redirect: to.fullPath } });

  if (
    to.meta.reqAuth &&
    to.meta.authLevel &&
    user.token &&
    to.meta.authLevel < user.authLevel
  )
    return next({ path: "/403", query: { redirect: to.fullPath } });

  if (to.meta.reqUnauth && user.token) return next({ path: "/home" });

  return next();
}

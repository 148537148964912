/** ----------------------------------------
    Imports
---------------------------------------- */

import StringHelper from './../../helpers/string';

/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'capitalize',
    fn: (string: string) => {
        return StringHelper.capitalize(string);
    }
}

export default filter;
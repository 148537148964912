

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        computed: {
            cClass() {
                const classes = ['form-group'];

                return classes.join(' ');
            }
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class FormGroup extends Vue {};
    

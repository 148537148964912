/** ----------------------------------------
    Imports
 ---------------------------------------- */

import { SettingsStore } from '@interface/store/Config';
import { StoreHelper } from '@fruitpackage/helpers';
import resetState from './state';

/** ----------------------------------------
    Mutations
 ---------------------------------------- */

let baseMutations = {
    setData(state: SettingsStore, data: object) {
        state.data = data;
    },

    setTimestamp(state: SettingsStore, data: string) {
        state.timestamp = data;
    },

    clearState(state: SettingsStore) {
        let originalState = resetState();
        Object.keys(originalState).forEach(key => {
            state[key] = originalState[key];
        });
    }
};

const mutations = { 
    ...baseMutations, 
    ...StoreHelper.resetStateMutation(resetState)
}

/** ----------------------------------------
    Export Mutations
 ---------------------------------------- */

export default mutations;
/** ----------------------------------------
    State
 ---------------------------------------- */

const state = () => {
    return {
        data: {},
        timestamp: null
    }
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default state;
/** ----------------------------------------
    Imports
---------------------------------------- */

import { mapState, mapGetters } from 'vuex';
import { AppStore } from '@interface/store/App';

/** ----------------------------------------
    Plugin
---------------------------------------- */

const AppPlugin = {
    install(Vue) {

        Vue.mixin({
            computed: {
                ...mapState({ 
                    user: (state: AppStore) => state.user,
                }),

                ...mapGetters({
                    isLoggedIn: 'user/isLoggedIn'
                }),
            },
        });
    }
};

/** ----------------------------------------
    Exports
---------------------------------------- */

export default AppPlugin;
/** ----------------------------------------
    Imports
 ---------------------------------------- */

import { Meta } from './meta';
const meta = new Meta();

/** ----------------------------------------
     Helpers
 ---------------------------------------- */

import { capitalizeFirstLetter } from './../helpers/utils';

/** ----------------------------------------
    Generator Helper
 ---------------------------------------- */

export default class RouterHelper {
    
    /**
     * @param  {[object]} context
     * @param  {any} middleware
     * @param  {number} index
     */
    static nextFactory(context: [object], middleware: any, index: number) {
        const subsequentMiddleware = middleware[index];
    
        if (!subsequentMiddleware) return context.next;
    
        return (...parameters) => {
            context.next(...parameters);
            const nextMiddleware = this.nextFactory(context, middleware, index + 1);
            subsequentMiddleware({ ...context, next: nextMiddleware });
        };
    }
    
    /**
     * @param  {[object]} router
     * @param  {[object]} to
     * @param  {[object]} from
     * @param  {any} next
     */
    static handleMiddleware(router: [object], to: [object], from: [object], next: any) {
        to.matched.some(record => {
            if (!record.meta.middleware) return next();
    
            const middleware = Array.isArray(record.meta.middleware)
                ? record.meta.middleware
                : [record.meta.middleware];
    
            const context = {
                from,
                next,
                router,
                to: record,
            };
            const nextMiddleware = this.nextFactory(context, middleware, 1);
    
            return middleware[0]({ ...context, next: nextMiddleware });
        });
    }

    static handleSeo(router: [object], to: [object], from: [object], next: any, config: object) {

        /** ----------------------------------------
            Route Meta
         ---------------------------------------- */

        const head = config.settings;

        const pageTitle= '';
        to.meta.title && (pageTitle == to.meta.title);

        const title = [
            head.pre,
            head.pre && head.seperator,
            pageTitle,
            pageTitle && head.post && head.seperator,
            head.post
        ];

        const headFilter = title.filter(el => {
            return el != null;
        });

        const seo = {
            title: headFilter.join(' '),
            description: to.meta.description,
            keywords: to.meta.keywords,
            image: to.meta.image,
            url: to.path
        };

        const defaultValues = {
            title: [
                'og:title',
            ],
            description: [
                'description',
                'og:description',
            ],
            keywords: [],
            image: [],
            url: [
                'url',
                'og:url'
            ]
        };

        const values = config || defaultValues;

        /** ----------------------------------------
            Set Meta Helper
         ---------------------------------------- */

        const implementMetaTag = (name, types) => {
            seo[name] && types.map(type => {
                meta.tag = seo[name] && [type, seo[name]];
            });

            !seo[name] && types.map(type => {
                meta.remove = seo[name] && type;
            });
        };

        meta.title = seo.title && seo.title;

        Object.keys(seo).map(name => {
            if(name !== 'settings')
                implementMetaTag(name, values[name]);
        });

        /** ----------------------------------------
            Fallback Meta Helper
         ---------------------------------------- */

        to.meta.seo && to.meta.seo.then(data => {
            Object.keys(seo).map(name => {
                if(seo[name] != undefined && data[name] || name !== 'settings') {
                    implementMetaTag(name, values[name]);
                }
            });
        });

        /** ----------------------------------------
            Set Global Meta
         ---------------------------------------- */

        next();

    }
}
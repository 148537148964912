/** ----------------------------------------
    Interface
 ---------------------------------------- */

import { AppStore } from '@interface/store/App';

/** ----------------------------------------
    State
 ---------------------------------------- */

const getters = {
  
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default getters
/** ----------------------------------------
    Currency Symbols
---------------------------------------- */

const symbol: any = {
    'dollar': '$',
    'euro': '€',
    'pound': '£'
};

/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'currency-symbol',
    fn: (number: number, value: string = 'euro') => {
        return `${ symbol[value] } ${ number.toFixed(2).toLocaleString() }`;
    }
}

export default filter;
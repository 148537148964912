/**
 * This mixin adds the object helper to the data
 */

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import ObjectHelper from '../helpers/object';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    methods: {
        isValidPath(str) {
            return ObjectHelper.isValidPath(this, str);
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class ObjectHelperMixin extends Vue {}


/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Mixins, Prop, Emit, Vue } from 'vue-property-decorator';
import { SlotsMixin } from './../mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                clicked: false,
            };
        },

        mixins: [
            SlotsMixin, 
        ],

        computed: {
            cClass() {
                let classes = ['btn'];

                if (this.disabled)
                    classes.push('is-disabled');

                return classes.join(' ');
            },
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Button extends Vue{
        @Prop() to!: string;

        @Prop(Boolean) prevent: boolean;

        @Prop(Boolean) disabled: boolean;

        @Emit()
        click(event) {
            if (this.prevent || this.disabled)
                event.preventDefault();

            this.clicked = true;
            
        }
};

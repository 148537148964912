/** ----------------------------------------
    Interface
 ---------------------------------------- */

import { ModelStore } from '@interface/store/Model';

/** ----------------------------------------
    State
 ---------------------------------------- */

export default {
    data: (state: ModelStore) => (id: number) => {
        return id;
    }
};
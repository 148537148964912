/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'toArray',
    fn: (value: any) => {
        return (value) ? Array.from(value) : [];
    }
}

export default filter;
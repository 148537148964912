/** ----------------------------------------
    Imports
 ---------------------------------------- */

import store from '@store/store';

/** ----------------------------------------
    Logout
 ---------------------------------------- */

export default function logout({ next }) {
    store.dispatch('user/logout');
}
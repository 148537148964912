/** ----------------------------------------
   Actions
 ---------------------------------------- */

const actions = {

    clearStore({ commit, dispatch }: any) {
        commit('user/clearState');
        dispatch('clearApp');
    },

    clearApp({ commit }: any) {
        // commit('content/clearState');
    },
};

/** ----------------------------------------
   Export Actions
 ---------------------------------------- */

export default actions;
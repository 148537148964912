/* ----------------------------------------
    Vue
---------------------------------------- */

import Vue from 'vue';
import App from '@/App.vue';
import router from '@router/router';
import store from '@store/store';
import { app } from '@config';

/* ----------------------------------------
    Package
---------------------------------------- */

import VeeValidate, { Validator } from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl';
import FruitPackage from '@fruitpackage';
import AppPlugin from '@plugins/app';
import VueLazyload from 'vue-lazyload';
import VScrollLock from 'v-scroll-lock';

/* ----------------------------------------
    Vue Events
---------------------------------------- */

import './events/keys';

/* ----------------------------------------
    Vue Filters
---------------------------------------- */

import './filters/filters';

/* ----------------------------------------
    Vue Directives
---------------------------------------- */

import './directives/directives';

/* ----------------------------------------
    Vue Registration
---------------------------------------- */

import './service/Registration';

/* ----------------------------------------
    Vue Mixins
---------------------------------------- */

import './mixins/mixins';

/* ----------------------------------------
    Vue Validation
---------------------------------------- */

import '@fruitpackage/validation/iban';
import '@fruitpackage/validation/password';
import '@fruitpackage/validation/dutch-postal';
import '@fruitpackage/validation/dutch-phone';

/* ----------------------------------------
    Set Bugherd
---------------------------------------- */

import { Bugherd } from '@helpers/Bugherd';

/* ----------------------------------------
    Vue Configuration
---------------------------------------- */

Vue.config.productionTip = false;
Vue.config.devtools = true;

/* ----------------------------------------
    Vue Plugins
---------------------------------------- */

Vue.use(VeeValidate);
Validator.localize('nl', nl);

Vue.use(VScrollLock);
Vue.use(FruitPackage);
Vue.use(AppPlugin);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    // error: 'http://giphygifs.s3.amazonaws.com/media/5xi6Vc5Qj5ZGo/giphy.gif',
    // loading: 'https://i.stack.imgur.com/HDcAB.gif',
    attempt: 2
});

/* ----------------------------------------
    Vue Errors
---------------------------------------- */

// Vue.config.errorHandler = (err, vm, info) => {
//     console.log('[Global Error Handler]: Error in ' + info + ': ' + err);
// };

// Vue.config.warnHandler = (msg, vm, trace) => {
//     console.log('[Global Warn Handler]: Warning in ' + trace + ': ' + msg);
// };

/* ----------------------------------------
    Vue Data
---------------------------------------- */

const env = {
    type: process.env.NODE_ENV,
    scheme: process.env.VUE_APP_SCHEME,
    host: process.env.VUE_APP_HOST,
    port: process.env.VUE_APP_PORT,
};

env.baseUrl = (env.port) ? `${ env.scheme }${ env.host }:${ env.port}` : env.host;

const path = {
    start: window.location.pathname
};

const log = {
    type: process.env.LOGNAME
};

/* ----------------------------------------
    Initiate Vue
---------------------------------------- */

new Vue({
    el: '#app',
    data: {
        path: path,
        env: env,
        log: log,
        public: true,
    },
    router,
    store,
    render: app => app(App)
});
new Bugherd();

/** ----------------------------------------
    State
 ---------------------------------------- */

const state = {
  navData: {},
  selectedAmbassador: {
    id: null,
    index: null
  },
  selectedFilters: {
    ambassador: [],
    location: [],
    museum: [],
    category: [],
    date: [],
    genre: []
  },
  unfilteredEvents: {
    data: []
  },
  filteredEvents: {
    data: []
  },
  searchQuery: '',
  filteredMuseums: {},
  unfilteredMuseums: {},
  startLocation: {},
  activeHearts: {},
  reloadMyNightButton: false,
  cookieBanner: {},
  // mijn nacht element delete warning
  warningVisible: false,
  acceptingWarning: false,
  warningId: null,
  eventType: 'event_once'
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default state;

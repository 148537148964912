/** ----------------------------------------
     Service
 ---------------------------------------- */

import { ApplicationService } from '@store/service/ApplicationService';

/** ----------------------------------------
     Actions
 ---------------------------------------- */

const actions = {
    async fetch({ commit }: any, payload: any) {
        const applicationService = new ApplicationService('api', this);
        applicationService.getModel(payload)
            .then(results => {
                let models = {}
                results.forEach(model => {
                    models[model.id] = model;
                });
                commit('setData', { 
                    model: payload, 
                    items: models
                });
                commit('setTimestamp', new Date().getTime());
            });
    },

    updateData({ commit }: any , payload: any) {
        commit('updateData', payload);
    },
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default actions;


/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Emit, Watch, Mixins, Vue } from 'vue-property-decorator';
import { DropPositionMixin } from './../mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                active: false,
                onHover: !this.onClick,
                proxyPosition: null,
                dropClass: 'tooltip__target',
                whitespace: 'nowrap',
            };
        },

        mixins: [
            DropPositionMixin,
        ],

        created() {
            this.proxyPosition = this.position;
        },

        mounted() {
            this.rootListener();
        },

        computed: {
            cClass() {
                let classes = ['tooltip'];

                if (this.active)
                    classes.push('is-active');

                return classes.join(' ');
            },

            cTargetClass() {
                let classes = ['tooltip__target'];

                classes.push(`tooltip__target--${ this.proxyPosition }`);
                classes.push(`tooltip__target--${ this.whitespace }`);

                return classes.join(' ');
            },
        },

        methods: {
            rootListener() {
                this.$root.$on('root::click', (event) => {
                    this.active = false;
                });
            },

            resetWhitespace() {
                this.whitespace = 'nowrap';
            },

            updateWhitespace() {
                const target = this.$el.querySelector('.tooltip__target');
                const content = this.$el.querySelector('.tooltip__content');
                const targetRect = target.getBoundingClientRect();
                const contentRect = content.getBoundingClientRect();

                if (contentRect.width > targetRect.width)
                    this.whitespace = 'wrap';
            },
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Tooltip extends Vue {
        @Prop({ default: 'center' }) position!: string;

        @Prop(Boolean) onClick!: boolean;

        @Prop(Boolean) follow!: boolean;

        @Emit()
        click(event) {
            if (!this.onClick) return;

            this.active = true;
            
        }

        @Emit()
        mouseover(event) {
            if (!this.onHover) return;

            this.active = true;
            
        }

        @Emit()
        mousemove(event) {
            if (!this.onHover || !this.follow) return;
            
        }

        @Emit()
        mouseout(event) {
            if (!this.onHover) return;

            this.active = false;
            
        }

        @Watch('active')
        onActiveChange(val: boolean) {
            if (!val) {
                this.resetWhitespace();
                this.resetProxyPosition();
                return;
            }

            this.$nextTick(function () {
                this.updateWhitespace();
                this.updateProxyPosition();
            });
        }
};

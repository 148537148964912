/* ----------------------------------------
    Imports
---------------------------------------- */

import { ApiService } from './ApiService';

/* ----------------------------------------
    API Service
---------------------------------------- */

export class PagesService extends ApiService {

    /* ----------------------------------------
        Constructor
    ---------------------------------------- */

    protected constructor(apiName: string, store) {
        super(apiName, 'content', store);
    }

    /* ----------------------------------------
        Constructor
    ---------------------------------------- */

    async getContent() {
        return this.get().then(response => {
            if (response instanceof Error)
                throw response;

            return response;
        });
    }
}
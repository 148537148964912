/**
 * This mixin updates the proxyPosition from a drop overlay based on the window size
 */

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({

    methods: {
        resetProxyPosition() {
            this.proxyPosition = this.position;
        },

        updateProxyPosition() {
            if (!this.active) return;

            const box = this.$el.querySelector(`.${ this.dropClass }`);
            const boxRect = box.getBoundingClientRect();

            if (boxRect.left < 0 && (this.position == 'right' || this.position == 'center'))
                this.proxyPosition = 'left';

            if (boxRect.left + boxRect.width > window.innerWidth && (this.position == 'left' || this.position == 'center'))
                this.proxyPosition = 'right';
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class DropPositionMixin extends Vue {}
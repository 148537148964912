/* ----------------------------------------
    API Service
---------------------------------------- */

/**
 * An abstract class for communicating with an API
 */

import axios from 'axios';

export default abstract class ApiService {

    axios: any;

    /** ----------------------------------------
        Constructor
     ---------------------------------------- */

    protected constructor(baseUrl: string) {
        this.axios = axios.create();
        this.setBaseUrl(baseUrl);
    }

    /** ----------------------------------------
        Get
     ---------------------------------------- */

    public async get(url: string, headers: Object = {}) {
        return this._send({
            method: 'get',
            url: url,
            headers: headers
        });
    }

    /** ----------------------------------------
        Post
     ---------------------------------------- */

    public async post(url: string, data: [object], headers: Object = {}) {
        return this._send({
            method: 'post',
            url: url,
            data: data,
            headers: headers
        });
    }

    /** ----------------------------------------
        Put
     ---------------------------------------- */

    public async put(url: string, data: [object], headers: Object = {}) {
        return this._send({
            method: 'put',
            url: url,
            data: data,
            headers: headers
        });
    }

    /** ----------------------------------------
        Delete
     ---------------------------------------- */

    public async delete(url: string, headers: Object = {}) {
        return this._send({
            method: 'delete',
            url: url,
            headers: headers
        });
    }

    /** ----------------------------------------
         Custom
     ---------------------------------------- */

    public async custom(options: [object]) {
        return this._send(options);
    }

    /** ----------------------------------------
        Send
     ---------------------------------------- */

    private _send(options: any) {
        return this.axios(options).then(result => {
            return result.data;
        }).catch(error => {
            return error;
        });
    }

    /** ----------------------------------------
        Set Base URL
     ---------------------------------------- */

    public setBaseUrl(baseUrl) {
        this.axios.defaults.baseURL = baseUrl;
    }

    /** ----------------------------------------
        Set header of method
     ---------------------------------------- */

    public setHeader(method: string, value: [object]) {
        this.axios.defaults.headers[method] = value;
    }

    /** ----------------------------------------
        Add header of method
     ---------------------------------------- */

    public addHeader(method: string, key: string, value: string) {
        this.axios.defaults.headers[method][key] = value;
    }

    /** ----------------------------------------
        Delete header of method
     ---------------------------------------- */

    public deleteHeader(method: string, key: string) {
        delete this.axios.defaults.headers[method][key];
    }

    /** ----------------------------------------
        Add response interceptor
     ---------------------------------------- */

    public addResponseInterceptor(successFn?: (config: [object]) => any, errorFn?: (error: Error) => any) {
        return this.axios.interceptors.response.use(successFn, errorFn);
    }

    /** ----------------------------------------
        Delete response interceptor
     ---------------------------------------- */

    public deleteResponseInterceptor(successFn?: (config: [object]) => any, errorFn?: (error: Error) => any) {
        this.axios.interceptors.response.eject(successFn, errorFn);
    }

    /** ----------------------------------------
        Add request interceptor
     ---------------------------------------- */

    public addRequestInterceptor(successFn?: (config: [object]) => any, errorFn?: (error: Error) => any) {
        return this.axios.interceptors.request.use(successFn, errorFn);
    }

    /** ----------------------------------------
        Delete request interceptor
     ---------------------------------------- */

    public deleteRequestInterceptor(successFn?: (config: [object]) => any, errorFn?: (error: Error) => any) {
        this.axios.interceptors.request.eject(successFn, errorFn);
    }
}
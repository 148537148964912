/** ----------------------------------------
    Vue
 ---------------------------------------- */

import Vue from 'vue';

/* ----------------------------------------
    Configuration
---------------------------------------- */

import { app } from '@config';

/* ----------------------------------------
    Lodash
---------------------------------------- */

import { upperFirst, camelCase } from 'lodash';

/* ----------------------------------------
    Require Global Components
---------------------------------------- */

/**
 * This function is responsible for handling
 * the primary configuration in registering
 * base components
 *
 * @path     string          component folder
 * @deep     boolean         look for sub folders
 * @regex    string          filter matches
 */

const globalComponents = require.context('./../components/globals', false, /Global[A-Z]\w+\.(vue|js)$/);
const iconComponents = require.context('./../../resources/svg/', false, /Icon[A-Z]\w+\.(vue|js)$/);

const requireComponents = [
    globalComponents,
    iconComponents
]

/* ----------------------------------------
    Register Global Components
---------------------------------------- */

requireComponents.forEach(requireComponent => {

    requireComponent.keys().map(fileName => {

        /* ----------------------------------------
            Retrieve Configuration
        ---------------------------------------- */

        const componentConfig = requireComponent(fileName);

        /* ----------------------------------------
            Retrieve Files
        ---------------------------------------- */

        const componentName = upperFirst(
            camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
        );

        /* ----------------------------------------
            Global Registration
        ---------------------------------------- */

        Vue.component(componentName, componentConfig.default || componentConfig);

    });

});
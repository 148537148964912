/** ----------------------------------------
     State
 ---------------------------------------- */

import router from '@router/router';

const state = () => {
    return {
        data: {
            router: router,
        }
    }
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default state;
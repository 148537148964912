/** ----------------------------------------
    Vue Router
 ---------------------------------------- */

import Vue from "vue";
import Router from "vue-router";

/** ----------------------------------------
    Routes
 ---------------------------------------- */

import routes from "@router/routes/routes";
import { RouterHelper } from "@fruitpackage/helpers";
console.log("🔥: routes", routes);

/** ----------------------------------------
    Initiate Vue Router
 ---------------------------------------- */

Vue.use(Router);

/** ----------------------------------------
    Router Configuration
 ---------------------------------------- */

const createRouter = () =>
  new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
      return { x: 0, y: 0 };
    }
  });

const router = createRouter();

/** ----------------------------------------
    Handle Middleware
 ---------------------------------------- */

import { meta } from '@config';

router.beforeEach((to: [object], from: [object], next: any) => {
    RouterHelper.handleMiddleware(router, to, from, next);
});

/** ----------------------------------------
    Export Modules
 ---------------------------------------- */

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // the relevant part
}

export default router;

/** ----------------------------------------
     Vuex State
 ---------------------------------------- */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

/** ----------------------------------------
     Export State
 ---------------------------------------- */

export default {
    namespaced: true,
    actions: actions,
    getters: getters,
    mutations: mutations,
    state: state
};
/** ----------------------------------------
    Error routes
---------------------------------------- */

const routes = [
    {
        path: '/404',
        name: '404/:id',
        component: () => import('@view/errors/404.vue')
    },
    {
        path: '/403',
        name: '403/:id',
        component: () => import('@view/errors/403.vue')
    }
],
 
/** ----------------------------------------
    Export Modules
---------------------------------------- */

export default routes;
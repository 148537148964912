/** ----------------------------------------
    Currency Code
---------------------------------------- */

const code: any = {
    'dollar': 'USD',
    'euro': 'EUR',
    'pound': 'GBP'
};

/** ----------------------------------------
    Currency Symbols
---------------------------------------- */

const symbol: any = {
    'dollar': '$',
    'euro': '€',
    'pound': '£'
};

/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'currency',
    fn: (number: number, type: boolean, value: string = 'euro') => {
        return `${ !type && symbol[value] || type && code[value] } ${ number.toFixed(2).toLocaleString() }`;
    }
}

export default filter;
/* ----------------------------------------
    Api Communicator
---------------------------------------- */

import axios from 'axios';
import store from '@store/store';

/**
 * Will help building routes
 */

export default class ApiCommunicator {

    vue: any;

    /** ----------------------------------------
        Constructor
     ---------------------------------------- */

    constructor(vue: any) {
        this.vue = vue;
    }

    /** ----------------------------------------
         Get model routes
    ---------------------------------------- */

    public async dispatchAction(action) {
        return await store.dispatch(action);
    }

    public async fetchConfig() {
        return this.dispatchAction('config/fetch');
    }

    public async fetchRoutes() {
        return this.dispatchAction('routes/fetch');
    }

    public async fetchGlobals() {
        return this.dispatchAction('globals/fetch');
    }

    public async fetchUser() {
        return this.dispatchAction('user/fetch');
    }

    public async putGlobals() {
        return this.dispatchAction('globals/store');
    }

    public async clearAppStore() {
        return axios.all([
            this.dispatchAction('clearApp'),
        ]).catch(error => {
            return new Error(error);
        });
    }

    public async fetchApp() {
        return axios.all([
            // this.fetchConfig(),
            // this.fetchGlobals(),
            this.fetchRoutes(),
        ]).catch(error => {
            return new Error(error);
        });
    }
}
/** ----------------------------------------
    IBAN
---------------------------------------- */

import { Validator } from 'vee-validate';

Validator.extend('iban', {
    validate (value) {
        var regex = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i;
        return regex.test(value);
    },
    getMessage (field) {
        return `The ${field} field must be a valid iban number`;
    }
});
/* ----------------------------------------
    Imports
---------------------------------------- */

import { ApiService } from './ApiService';

/* ----------------------------------------
    API Service
---------------------------------------- */

export class UserService extends ApiService {

    /* ----------------------------------------
        Constructor
    ---------------------------------------- */

    protected constructor(apiName: string, store) {
        super('auth', '', store);
    }

    /* ----------------------------------------
        User data
    ---------------------------------------- */

    public async getUserData(data: [object]) {
        return this.get('/me', data).then(response => {
            if (response instanceof Error)
                throw response;

            return response;
        });
    }

    /* ----------------------------------------
        Login
    ---------------------------------------- */

    public async postLogin(data: [object]) {
        return this.post('/login', data).then(response => {
            return response;
        });
    }

    /* ----------------------------------------
        Register
    ---------------------------------------- */

    public async postRegister(data: [object]) {
        return this.post('/register', data).then(response => {
            return response;
        });
    }

    /* ----------------------------------------
        Logout
    ---------------------------------------- */

    public async getLogout() {
        return this.get('/logout').then(response => {
            if (response instanceof Error)
                throw response;

            return response;
        });
    }

    /* ----------------------------------------
        Forgot
    ---------------------------------------- */

    public async postForgot(data: [object]) {
        return this.post('/forgot', data).then(response => {
            return response;
        });
    }

    /* ----------------------------------------
        Password
    ---------------------------------------- */

    public async postPassword(data: [object]) {
        return this.post('/password', data).then(response => {
            return response;
        });
    }

    /* ----------------------------------------
        Reset
    ---------------------------------------- */
    public async postReset(data: [object]) {
        return this.post('/reset', data).then(response => {
            return response;
        });
    }

    /* ----------------------------------------
        User data
    ---------------------------------------- */

    public async putUpdate(id: string, data: [object]) {
        return this.put(`/user/${ id }`, data).then(response => {
            return response;
        });
    }
}
/** ----------------------------------------
    Imports
---------------------------------------- */

import StringHelper from './../../helpers/string';

/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'trim',
    fn: (string: string, length: number) => {
        return StringHelper.trim(string, length);
    }
}

export default filter;
/** ----------------------------------------
     Local Configuration
 ---------------------------------------- */

const country = {
    LOCAL: 'NL',
    COUNTRY: {
        nl: 'Nederland',
        eng: 'Netherlands'
    },
    CURRENCY: 'euro'
};

/** ----------------------------------------
     Time
 ---------------------------------------- */

const time = {
    DATE_FORMAT: 'DD:MM:YYYY',
    TIME_FORMAT: 'HH:MM',
    TIME_ZONE: 'Central European Summer Time',
};

/** ----------------------------------------
     Module Export
 ---------------------------------------- */

module.exports = {
    country,
    time
};
/** ----------------------------------------
    Meta Tags
 ---------------------------------------- */

/**
 * This class will help chang meta data based
 * on several segments of responsibilities.
 */

export abstract class Meta {

    /** ----------------------------------------
        Types
     ---------------------------------------- */

    private _head: any;
    private _tags: [];
    private _title: any;

    /** ----------------------------------------
        Constructor
     ---------------------------------------- */

    /**
     * This constructor will store all the basic
     * DOM elements that are used within this class.
     */

    protected constructor() {
        this._head = document.head;
        this._tags = this._head.querySelectorAll('meta');
        this._title = '';
    }

    /** ----------------------------------------
        Public Getters & Setters
     ---------------------------------------- */

    set tag(meta: object) {
        this._tags = this._head.querySelectorAll('meta');
        this.createTag(meta[0], meta[1]);
    }

    set remove(meta: string) {
        this.deleteTag(meta);
    }

    set title(title: string) {
        if(document.title != title) {
            this._title = title;
            document.title = this._title;
        }
    }

    /** ----------------------------------------
        Helper
     ---------------------------------------- */

    private findTag(type) {
        const data = [...this._tags]
            .map(tag => tag.attributes)
            .filter(tag => tag[0].value === type && tag);

        return data;
    }

    /** ----------------------------------------
        Create Meta
     ---------------------------------------- */

    private create(type, content) {
        const tag = document.createElement('meta');
        tag.setAttribute('property', type);
        tag.content = content;
        this._head.appendChild(tag);
    }

    private createTag(type, content) {
        const tag = this.readTag(type);

        if(!tag) {
            this.create(type, content);
        } else {
            this.updateTag(type, content);
        }
    }

    /** ----------------------------------------
        Read Meta
     ---------------------------------------- */

    private read(type) {
        const data = [...this._tags]
            .map(tag => tag.attributes)
            .filter(tag => tag[0].value === type);

        return typeof data != 'undefined' ? data[0] : false;
    }

    private readTag(type) {
        const meta = this.read(type);
        if(!meta) return false;

        return {
            name: meta[0].value,
            content: meta[1].value
        }
    }

    /** ----------------------------------------
        Update Meta
     ---------------------------------------- */

    private update(type) {
        return this.findTag(type)[0];
    }

    private updateTag(type, content) {
        const meta = this.update(type);
        meta[1].value = content;
    }

    /** ----------------------------------------
        Delete Meta
     ---------------------------------------- */

    private delete(type) {
        return [...this._tags]
            .filter(tag => {
                return tag.attributes[0].value === type && tag
            });
    }

    private deleteTag(type) {
        const tag = this.readTag(type);
        if(!tag) return;
        const meta = this.delete(type);
        meta[0].remove();
    }

}
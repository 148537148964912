/** ----------------------------------------
    Vue
 ---------------------------------------- */

import Vue from 'vue';

/* ----------------------------------------
    Vue Keycodes
---------------------------------------- */

/**
 * Use these additional keycodes by adding
 * the event to a element within the DOM
 * of the template v-on:keyup.custom="method"
 */

Vue.config.keyCodes = {
    'escape':               27,             // escape
    'exit':                 [8, 27],        // backspace + escape
    'delete':               8,              // backspace
    'enter':                13,             // enter
    'media-next':           176,            // next
    'media-prev':           177,            // prev
    'media-stop':           178,            // stop
    'media-play-pause':     179             // play/pause
};
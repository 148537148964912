/** ----------------------------------------
    Imports
---------------------------------------- */

import moment from 'moment';

/** ----------------------------------------
    Filter
---------------------------------------- */

const filter = {
    name: 'date',
    fn: (timestamp: number, locale: string) => {
        if (locale)
            moment.locale(locale);

        const m = new moment(timestamp);
        return m.format('LL');
    }
}

export default filter;
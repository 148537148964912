

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Prop, Component } from 'vue-property-decorator';
import FormChoice from './Choice.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({})

/* ----------------------------------------
        Exports
    ---------------------------------------- */

export default class FormCheckbox extends FormChoice {
        @Prop({ default: 'checkbox' }) type!: string;
}


/** ----------------------------------------
    Imports
 ---------------------------------------- */

import { RoutesStore } from '@interface/store/Routes';
import { StoreHelper } from '@fruitpackage/helpers';
import resetState from './state';

/** ----------------------------------------
    Mutations
 ---------------------------------------- */

let baseMutations = { 
    setMenu(state: RoutesStore, data: array) {
        state.menu = data;
    },

    setRoutes(state: RoutesStore, data: array) {
        state.routes = data;
    },

    clearState(state: RoutesStore) {
        state = resetState();
    }
};

const mutations = { 
    ...baseMutations, 
    ...StoreHelper.resetStateMutation(resetState)
}

/** ----------------------------------------
    Export Mutations
 ---------------------------------------- */

export default mutations;
/** ----------------------------------------
    Imports
 ---------------------------------------- */

import { ModelStore } from '@interface/store/Model';
import { StoreHelper } from '@fruitpackage/helpers';
import resetState from './state';

/** ----------------------------------------
    Mutations
 ---------------------------------------- */

let baseMutations = { 
    setData(state: ModelStore, data: any) {
        state.data[data.model] = data.items;
    },

    updateData(state: ModelStore, data: any) {
        state.data[data.model][data.id] = data.data;
    },

    setTimestamp(state: ModelStore, data: string) {
        state.timestamp = data;
    },

    clearState(state: ModelStore) {
        state = resetState();
    }
};

const mutations = { 
    ...baseMutations, 
    ...StoreHelper.resetStateMutation(resetState)
}

/** ----------------------------------------
    Export Actions
 ---------------------------------------- */

export default mutations;
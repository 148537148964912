/** ----------------------------------------
    IBAN
---------------------------------------- */

import { Validator } from 'vee-validate';

Validator.extend('password', {
    validate (value) {
        var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        return regex.test(value);
    },
    getMessage (field) {
        return `Het wachtwoord moet tenminste 8 karakters lang zijn en een hoofdletter, kleine letter en nummer bevatten.`;
    }
});
/* ----------------------------------------
    Imports
---------------------------------------- */

import { ApiService as BaseApiService } from '@fruitpackage/services';
import { app } from '@config';
import router from '@router/router';

/* ----------------------------------------
    API Service
---------------------------------------- */

export abstract class ApiService extends BaseApiService {

    /* ----------------------------------------
        Construtor
    ---------------------------------------- */

    protected constructor(api: string, path: string = '', store: any) {
        super(app.api[api] + path);

        if (store.state.user.token)
            this.addTokenBearerHeader(store.state.user.token);

        this.addUnautherizedResponseInterceptor(store);
    }

    /* ----------------------------------------
        Add Token Bearer Header
    ---------------------------------------- */

    public addTokenBearerHeader(token: string) {
        this.addHeader('common', 'Authorization', 'Bearer ' + token);
    }

    /* ----------------------------------------
        Add Token Bearer Header
    ---------------------------------------- */

    public addApplicationIdHeader(id: any) {
        this.addHeader('common', 'Application-Id', id);
    }

    /* ----------------------------------------
        Add unautherized response interceptor
    ---------------------------------------- */

    addUnautherizedResponseInterceptor(store: any) {
        this.addResponseInterceptor(undefined, function (error: Error) {
            if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                store.dispatch('user/removeToken');
                router.push('/login');
            }
            throw error;
        });
    }
}
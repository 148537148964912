

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Model, Emit, Watch, Vue, Provide } from 'vue-property-decorator';
import { SlotsMixin } from './../mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                currentTab: -1,
                tabs: []
            };
        },

        mixins: [
            SlotsMixin, 
        ],

        created() {
            this.$nextTick(() => {
                this.updateTabs();
            });
        },

        activated() {
            this.$nextTick(() => {
                this.updateTabs();
            });
        },

        mounted() {
            this.$nextTick(() => {
                this.updateTabs();
            });
        },

        computed: {
            cClass() {
                let classes = ['tabs'];

                return classes.join(' ');
            },
        },

        methods: {
            // Retrieve list of tab children in slot
            getTabs() {
                return (this.$slots.default || [])
                    .map(vnode => vnode.componentInstance)
                    .filter(tab => tab && tab._isTab);
            },

            // Update tabs
            updateTabs() {
                const tabs = this.getTabs();

                let index = parseInt(this.value, 10);
                index = isNaN(index) ? 0 : index;

                if (this.remember) {
                    const rootIndex = this.getFromRoot();
                    if (rootIndex)
                        index = rootIndex;
                }

                this.tabs = tabs;
                this.currentTab = index;
            },
            
            // Add index in the root data
            addToRoot(index) {
                if (!this.$root.$data.tabs)
                    this.$root.$data.tabs = {};

                this.$root.$data.tabs[this.name] = index;
            },

            // Get index in the root data
            getFromRoot() {
                if (this.$root.$data.tabs && this.$root.$data.tabs[this.name])
                    return this.$root.$data.tabs[this.name];

                return false;
            },
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Tabs extends Vue{
        @Prop() name!: string;

        @Prop(Boolean) remember!: boolean;

        @Provide() tabsName = this.name;

        @Provide() linkClass = 'tabs__link';

        @Model('input', { type: Number }) readonly value!: number

        @Emit()
        activateTab(tab) {
            if (!tab || tab.disabled) return false;

            const index = this.tabs.indexOf(tab);

            if (index < 0) return false;

            this.currentTab = index;

            return this.currentTab;
        }

        @Watch('currentTab')
        onCurrentTabChange(val: Number) {
            let index = -1;

            this.tabs.forEach((tab, idx) => {
                if (val === idx && !tab.disabled) {
                    tab.active = true;
                    index = idx;
                } else {
                    tab.active = false;
                }
            });

            if (this.remember)
                this.addToRoot(index);

            this.$emit('input', index);
        }
};

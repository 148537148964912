/** ----------------------------------------
    Vue
 ---------------------------------------- */

import Vue from 'vue';

/* ----------------------------------------
    Imports
---------------------------------------- */

import { TransitionMixin } from '@mixin/global/transition';

/* ----------------------------------------
    Vue
---------------------------------------- */

Vue.mixin(TransitionMixin);
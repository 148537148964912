/** ----------------------------------------
    Interface
 ---------------------------------------- */

import { UserStore } from '@interface/store/User';
import { StoreHelper } from '@fruitpackage/helpers';
import resetState from './state';

/** ----------------------------------------
    Mutations
 ---------------------------------------- */

let baseMutations = { 
    setToken(state: UserStore, data: string) {
        state.token = data;
    },

    emptyToken(state: UserStore) {
        state.token = '';
    },

    setData(state: UserStore, data: object) {
        state.data = data;
    },

    setTimestamp(state: UserStore, data: string) {
        state.timestamp = data;
    },
};

const mutations = { 
    ...baseMutations, 
    ...StoreHelper.resetStateMutation(resetState)
}

/** ----------------------------------------
    Export Mutations
 ---------------------------------------- */

export default mutations;
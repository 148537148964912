/** ----------------------------------------
    Auth routes
---------------------------------------- */

import auth from '@middleware/auth';
import logout from '@middleware/logout';

const routes = [
    {
        path: '/login',
        name: 'login/:id',
        meta: {
            middleware: [auth],
            reqUnauth: true,
        },
        component: () => import('@view/auth/Login.vue')
    },
    {
        path: '/register',
        name: 'register/:id',
        meta: {
            middleware: [auth],
            reqUnauth: true,
        },
        component: () => import('@view/auth/Register.vue')
    },
    {
        path: '/reset',
        name: 'reset/:id',
        meta: {
            middleware: [auth],
            reqUnauth: true,
        },
        component: () => import('@view/auth/Reset.vue'),
        beforeEnter(to, from, next) {
            // if (!to.query.email || !to.query.token) next('/login');
            next();
        }
    },
    {
        path: '/forgot',
        name: 'forgot/:id',
        meta: {
            middleware: [auth],
            reqUnauth: true,
        },
        component: () => import('@view/auth/Forgot.vue')
    },
    {
        path: '/logout',
        name: 'logout/:id',
        meta: {
            middleware: [auth, logout],
            reqAuth: true,
        }
    },
],
 
/** ----------------------------------------
    Export Modules
---------------------------------------- */

export default routes;
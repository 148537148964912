/** ----------------------------------------
    App
 ---------------------------------------- */

/**
 * Stores all the primary app configuration
 * that is needed to keep processes running!
 */

const app: any = require('./app.config');

/** ----------------------------------------
    Local
 ---------------------------------------- */

/**
 * Stores data about the preferred local
 * presets of the application.
 */

const local: object = require('./local.config');

/** ----------------------------------------
    Cookies
 ---------------------------------------- */

/**
 * Stores the primary values that have to be
 * stored within the clients sessions related
 * to cookies.
 */

const cookie: any = require('./cookie.config');

/** ----------------------------------------
    Bugherd
 ---------------------------------------- */

/**
 * Stores all the primary Bugherd configuration
 * that is needed to keep processes running!
 */

const bugherd: any = require('./bugherd.config');

/** ----------------------------------------
     Meta
 ---------------------------------------- */

/**
 * Stores data about the meta tags that need
 * to be implemented on routing.
 */

const meta: object = require('./meta.config');

/** ----------------------------------------
     Module Export
 ---------------------------------------- */

module.exports = {
    app: app.app,
    local: local,
    bugherd: bugherd.inject,
    consent: {
        status: cookie.state,
        settings: cookie.consent
    },
    meta: meta.meta,
};
/**
 * This mixin adds type logic specific for form inputs
 */

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Prop, Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    computed: {
        typeByConstructor() {
            let a = this.$vnode.componentOptions.tag.replace('FormInput', '');
            var type = '';
            for (let i = 0; i < a.length; i++) {
                if (a[i].toUpperCase() == a[i])
                    type+= (i) ? '-' : '';

                type+= a[i].toLowerCase();
            }
            return type;
        },

        cType() {
            if (this.type) return this.type;
            return this.typeByConstructor;
        },
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class FormInputTypeMixin extends Vue {
    @Prop({
        validator: (x) => ['url', 'text', 'password', 'email', 'search'].indexOf(x) !== -1
    }) type!: string;
}
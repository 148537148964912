/* ----------------------------------------
    Service
---------------------------------------- */

import { ApiService } from './ApiService';

/* ----------------------------------------
    API Service
---------------------------------------- */

export class ApplicationService extends ApiService {

    /* ----------------------------------------
        Constructor
    ---------------------------------------- */

    protected constructor(apiName: string, store) {
        super(apiName, '', store);
    }

    public async createApplication() {
        return this.post('new').then(response => {
            if (response instanceof Error)
                throw response;

            return response;
        });
    }

    public async getApplication(id: string = '') {
        id = id.toLowerCase();

        return this.get(id).then(response => {
            if (response instanceof Error)
                throw response;

            return response;
        });
    }

    public async updateApplication(id: string = '', data: object = {}) {
        id = id.toLowerCase();

        return this.put(id, data).then(response => {
            if (response instanceof Error)
                throw response;

            return response;
        });
    }

}
/* ----------------------------------------
    Generic
---------------------------------------- */

import * as fade from '@router/transitions/generic/fade';
import * as slide from '@router/transitions/generic/slide';

/* ----------------------------------------
    Create Functions
---------------------------------------- */

const transitions = [
    fade,
    slide
];

const generic = transitions.reduce((result, current) => {
    return Object.assign(result, current);
}, {});

/** ----------------------------------------
 Export Modules
 ---------------------------------------- */

export {
    generic
};